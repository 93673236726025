<template>
  <div class="sidebar">
		<div class="sidebar__scroll">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';
.sidebar{
	display: none;
	@include breakpoint(md){
		width: var(--sidebar-width);
		height: var(--sidebar-height);
		box-shadow: var(--sidebar-box-shadow);
		background: var(--sidebar-background);
		position: var(--sidebar-position);
		padding: var(--sidebar-padding);
		bottom: 0;
		overflow: hidden;
		display: block;
	}
}
.sidebar__scroll{
	overflow-y: scroll;
	height: var(--sidebar-container-height);
	width: var(--sidebar-container-width);
	padding: var(--sidebar-container-padding);
}
.page--layout-sidebar{
	.sidebar{
		grid-column-start: 1;
		grid-column-end: 2;
		grid-row-start: 2;
		grid-row-end: 4;
	}
}
</style>

<!-- Used in : DS -->